<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/finance_1' }"
          >代账报税服务</el-breadcrumb-item
        >
        <el-breadcrumb-item>凭证归档详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <div class="box">
        <p>
          方便、高效的管理工具，可以轻松地存储、整理、搜索和共享各种凭证、文件和资料。
          <span>产品特点：</span>
          <span
            >1.高效便捷：无需物理存储设备，随时随地访问，电子档案使信息管理变得轻松自如。</span
          >
          <span
            >2.安全可靠：采用先进的数据加密技术，保护文件和资料安全，免受恶意攻击。</span
          >
          <span
            >3.无限存储：提供了灵活的云存储空间，再多文件也可以放心储存。</span
          >
          <span>4.数据备份：定期进行数据备份，确保数据安全。</span>
          <span
            >5.易于分享：支持多种共享方式，可以更加方便地与同事、合作伙伴和客户轻松分享文件。
          </span>
          电子档案产品是一种高效、安全、易用、灵活且强大的管理工具，可以帮助用户轻松地管理自己的文件和资料，并且可以随时随地访问和共享这些文件和资料。
          我们致力于提供最优质的服务，帮助您实现数字生活的梦想。请随时联系我们。
        </p>
      </div>
    </div>

    <div class="btn" @click="subInfo">立即体验</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    subInfo() {
      this.$router.push({
        path: "/info",
        query: { org: "sz" },
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.box {
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}

.img_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.img_box span {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}

.img_box img {
  display: block;
  width: 100%;
}

.simg_box {
  display: flex;
  justify-content: center;
}

.img_box1 {
  width: 50%;
}

.img_box1 img {
  width: 200px;
  height: 350px;
}

.box p {
  font-size: 18px;
  color: #333333;
  line-height: 34px;
  padding: 40px 0;
  text-align: justify;
}

.box p:first-child {
  padding-top: 0;
}

.box p span {
  display: block;
  margin-bottom: 10px;
}

.btn {
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  background: #055afe;
  color: #fff;
  margin: 20px auto;
  cursor: pointer;
}

table tr th {
  text-align: center;
  padding: 5px;
  font-size: 14px;
}

table tr td {
  text-align: center;
  padding: 10px;
}
</style>
